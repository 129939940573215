<template>
  <div class="selection-display-root">
    <Modal
      class="selection-display-config-modal"
      :visible="editModalVisible"
      title="Selection display configuration"
      no-close-btn
      @close="onEditModalClose"
    >
      <EditSelectionDisplayModalContent
        :selection-data="selectedRow"
        :selection-parameters="allSelectionParameters"
        :selected-sport-id="selectedSportId"
        @close-modal="onEditModalClose"
        @data-update="onDataUpdate"
      />
    </Modal>
    <SportSidebar
      title="Sports"
      :disabled="isDataLoading"
      :selected-sport-id="selectedSportId"
      @update:selectedSportId="updateSelectedSport"
    />
    <div
      :class="[
        'selection-display-content',
        { 'sidebar-collapsed': sidebarCollapsed },
      ]"
    >
      <header class="selection-display-header">
        <Heading>
          Selection display configuration
        </Heading>
        <div class="filter-root">
          <TextInput
            class="team-search-input"
            :model-value="searchValue"
            @update:model-value="updateSearchValue"
            icon="search"
            :placeholder="'Search selections'"
            small
          />
        </div>
      </header>
      <div class="selection-display-body">
        <div v-if="!isDataLoading">
          <div class="selection-display-body-root">
            <Table
              class="selection-display-table"
              :columns="tableColumns"
              :rows="filteredSelectionDisplayConfigurations"
              :no-data-message="'No available market display configurations'"
              @row-clicked="onRowEdit"
            >
              <template #cell="{ column, row }">
                <TableCell
                  :column-key="column.key"
                  :row="row"
                  @data-update="onDataUpdate"
                />
              </template>
            </Table>
          </div>
        </div>
        <Spinner v-else />
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref, computed } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import {
  debounce, includes, toLower, filter, reduce, cloneDeep,
} from 'lodash';
import sportIds from '@/services/helpers/sports';
import { selectionDisplayConfigurationsTableColumns } from '@/services/helpers/selection-display-table-helper';
import { getSuperAdminData } from '@/services/helpers/super-admin';
import Heading from '@/components/common/Heading';
import SportSidebar from '@/components/common/SportSidebar';
import Spinner from '@/components/common/Spinner';
import TextInput from '@/components/common/TextInput';
import Table from '@/components/common/Table';
import Modal from '@/components/common/Modal';
import EditSelectionDisplayModalContent from './EditSelectionDisplayModalContent';
import TableCell from './table-config/TableCell';

const {
  FOOTBALL_ID,
} = sportIds;

export default {
  components: {
    Heading,
    SportSidebar,
    Spinner,
    TextInput,
    Table,
    TableCell,
    Modal,
    EditSelectionDisplayModalContent,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const isDataLoading = ref(false);
    const selectedSportId = ref(FOOTBALL_ID);
    const searchValue = ref('');
    const editModalVisible = ref(false);
    const selectedRow = ref();

    const allSelectionDisplayConfigurations = ref([]);
    const allSelectionParameters = ref([]);

    const tableColumns = ref(reduce(
      selectionDisplayConfigurationsTableColumns(),
      (fields, values, key) => [
        ...fields,
        {
          ...values,
          key,
        },
      ],
      [],
    ));

    const sidebarCollapsed = computed(() => !store.getters.sidebarExpanded);

    const updateSearchValue = debounce((val) => {
      searchValue.value = val;
      document.getElementById('table-scroller').scrollTo(0, 0);
    }, 300);

    const loadSelectionDisplayConfigurations = (sportId, rememberScrollLocation = false) => {
      isDataLoading.value = true;
      const tableScrollerPosition = document.getElementById('table-scroller').scrollTop;
      store.dispatch('loadSelectionDisplayConfigurations', sportId)
        .then((data) => {
          isDataLoading.value = false;
          allSelectionDisplayConfigurations.value = data;

          if (rememberScrollLocation) {
            setTimeout(() => {
              document.getElementById('table-scroller').scrollTo(0, tableScrollerPosition);
            }, 0);
          }
        })
        .catch(() => {
          isDataLoading.value = false;
          allSelectionDisplayConfigurations.value = [];
        });
    };

    const loadAllSelectionParameters = () => {
      store.dispatch('loadAllSelectionParameters')
        .then((data) => {
          allSelectionParameters.value = data;
        })
        .catch(() => {
          allSelectionParameters.value = [];
        });
    };

    const filteredSelectionDisplayConfigurations = computed(() => {
      if (!searchValue.value) return allSelectionDisplayConfigurations.value;
      return filter(allSelectionDisplayConfigurations.value,
        (market) => includes(toLower(market.marketCode), toLower(searchValue.value))
        || includes(toLower(market.longNamePattern), toLower(searchValue.value))
        || includes(toLower(market.shortNamePattern), toLower(searchValue.value)));
    });

    const updateSelectedSport = (sportId) => {
      selectedSportId.value = sportId;
      loadSelectionDisplayConfigurations(sportId);
      const query = cloneDeep(route.query);
      router.push({
        name: route.name,
        query: {
          ...query,
          sport: sportId,
        },
      });
    };

    const onRowEdit = (row) => {
      selectedRow.value = row;
      editModalVisible.value = true;
    };
    const onEditModalClose = () => { editModalVisible.value = false; };
    const onDataUpdate = (sportId) => loadSelectionDisplayConfigurations(sportId, true);

    const { isSuperAdmin } = getSuperAdminData();
    const selectedClient = computed(() => route.query.client || '');

    onMounted(() => {
      loadAllSelectionParameters();
      // Initial sport is set to AF
      updateSelectedSport(route.query.sport || FOOTBALL_ID);
    });

    return {
      isDataLoading,
      selectedSportId,
      searchValue,
      updateSearchValue,
      updateSelectedSport,
      filteredSelectionDisplayConfigurations,
      allSelectionParameters,
      tableColumns,
      sidebarCollapsed,
      editModalVisible,
      selectedRow,
      onEditModalClose,
      onRowEdit,
      onDataUpdate,
      selectedClient,
      isSuperAdmin,
    };
  },
};
</script>

<style lang="scss">
  .selection-display-root {
    .selection-display-config-modal {
      .modal__dialog {
        min-width: 550px;
        max-width: 650px;
        .modal__header {
          padding: 18px 16px 22px 16px;
          .modal__title {
            padding: 0;
          }
        }
      }
    }
    .selection-display-content {
      width: calc(100% - #{$sidebarWidth});
      height: 100%;

      &.sidebar-collapsed {
        width: calc(100% - #{$sidebarWidthCollapsed});
      }

      .selection-display-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 24px;
        .filter-root {
          display: flex;
          align-items: center;
          gap: 8px;
        }
      }
      .selection-display-body {
        .table {
          .table-row {
            cursor: pointer;
          }
        }
        .selection-display-body-root {
          header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 24px;
          }
          .selection-display-table {
            // Navbar height - 69px
            // Header height - 86px
            height: calc(100vh - 69px - 86px);
          }
        }
      }
    }

    .copy-to-other-operator-modal {
      .modal__dialog {
        width: 360px;
        min-width: 360px;
        max-width: 360px;
      }

      .modal__title {
        display: inline-flex;
        align-items: center;
        padding: 0;
        width: 100%;
        height: 100%;
      }

      .modal__header {
        padding: 0 16px;
        border-bottom: 1px solid #F0F0F0;
        height: 56px;
        min-height: 56px;
        max-height: 56px;
      }

      .modal__content {
        padding: 16px 0 0;
      }

      &__content {
        display: flex;
        flex-direction: column;
        gap: 16px;
        padding: 0 16px 16px;
      }

      &__footer {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 4px;
        padding: 0 16px;
        border-top: 1px solid #F0F0F0;
        height: 64px;
        min-height: 64px;
        max-height: 64px;
        margin-left: auto;
      }

      &__field {
        display: flex;
        flex-direction: column;
        gap: 2px;

        &-label {
          font-family: 'Rubik', sans-serif;
          font-size: 10px;
          font-weight: 600;
          line-height: 11.85px;
          color: #6D6D6D;
          text-transform: uppercase;
        }
      }
    }

    .super-admin-action-item {
      display: flex;
      align-items: center;
      gap: 4px;
      stroke: #191414;
    }

    .selection-display-upload-indicator {
      display: flex;
      align-items: flex-end;
      justify-content: center;
      background: transparentize(#191414, 0.3);
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 9999;
      padding: 0 0 32px;
    }

    .selection-display-upload-label {
      display: inline-flex;
      align-items: center;
      background-color: #FFFFFF;
      border: 1px solid #F0F0F0;
      height: 44px;
      min-height: 44px;
      max-height: 44px;
      white-space: nowrap;
      border-radius: 4px;
      padding: 0 12px;
    }
  }
</style>
