<template>
  <div class="edit-selection-modal-content">
    <div class="selection-info">
      <div class="selection-info-main-section">
        <div class="selection-info-item">
          <span class="selection-info-item-label">Selection code:</span>
          <TextInput
            :model-value="data.selectionCode"
            :placeholder="'Selection code'"
            no-clear-button
            small
            readonly
          />
        </div>
        <div class="selection-info-item">
          <span class="selection-info-item-label">Long name pattern:</span>
          <TextInput
            :model-value="data.longNamePattern"
            :placeholder="'Long name pattern'"
            no-clear-button
            small
            @update:modelValue="onLongNameChange"
          />
        </div>
        <div
          v-if="params.length"
          class="selection-info-item"
        >
          <span class="selection-info-item-label">Parameters:</span>
          <div
            v-for="param in params"
            :key="param.param"
            class="param-item"
          >
            <TextInput
              :model-value="param.label"
              no-clear-button
              small
              readonly
            />
          </div>
        </div>
        <div class="grouped-selection-info">
          <div class="selection-info-item">
            <span class="selection-info-item-label">Short name pattern:</span>
            <TextInput
              :model-value="data.shortNamePattern"
              :placeholder="'Short name pattern'"
              no-clear-button
              small
              @update:modelValue="onShortNameChange"
            />
          </div>
          <div class="half-width-item">
            <span class="selection-info-item-label">Position:</span>
            <TextInput
              :model-value="data.position"
              :placeholder="'Position'"
              no-clear-button
              small
              @update:modelValue="onPositionChange"
            />
          </div>
          <div class="selection-info-item toggle-item">
            <div class="toggle-item-content">
              <Toggle
                :model-value="data.active"
                @update:model-value="toggleIsSelectionActive"
              />
              <span class="toggle-label">Active</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="!confirmDeleteActive"
      class="selection-display-config-actions"
    >
      <div class="left-side">
        <div
          class="delete-btn"
          @click="toggleConfirmDelete"
        >
          Delete selection config
        </div>
      </div>
      <div class="right-side">
        <div
          @click="closeModal"
          class="dismiss-btn"
        >
          Dismiss
        </div>
        <Button
          :variant="'primary'"
          @click="onSaveChanges"
          :disabled="isSubmitDisabled"
        >
          Save changes
        </Button>
      </div>
    </div>
    <div
      v-else
      class="confirm-delete"
    >
      <div class="left-side">
        Confirm delete
      </div>
      <div class="right-side">
        <div
          @click="toggleConfirmDelete"
          class="dismiss-btn"
        >
          Dismiss
        </div>
        <Button
          :variant="'danger'"
          @click="deleteSelectionConfig"
        >
          Delete selection config
        </Button>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import {
  cloneDeep, isEqual, map, find,
} from 'lodash';
import TextInput from '@/components/common/TextInput';
import Button from '@/components/common/Button';
import Toggle from '@/components/common/Toggle';
import { getSportLabelBySportId } from '@/services/helpers/mappings-mapper';

export default {
  emits: ['closeModal', 'dataUpdate'],
  props: {
    selectionData: {
      type: Object,
      default: () => {},
    },
    selectionParameters: {
      type: Array,
      default: () => [],
    },
    selectedSportId: {
      type: String,
      default: () => '',
    },
  },
  components: {
    TextInput,
    Button,
    Toggle,
  },
  setup(props, { emit }) {
    const store = useStore();
    const originalData = ref(cloneDeep(props.selectionData));
    const data = ref(cloneDeep(props.selectionData));
    const dataValid = computed(() => {
      // Check for selection display model
      if (!data.value.position && data.value.position !== 0) return false;
      return true;
    });
    const isSubmitDisabled = computed(() => isEqual(originalData.value, data.value) || !dataValid.value);
    const confirmDeleteActive = ref(false);

    const onDefaultNameChange = (val) => { data.value.defaultName = val; };
    const onLongNameChange = (val) => { data.value.longNamePattern = val; };
    const onShortNameChange = (val) => { data.value.shortNamePattern = val; };
    const onPositionChange = (val) => { data.value.position = val ? parseInt(val, 10) : val; };
    const toggleIsSelectionActive = () => { data.value.active = !data.value.active; };

    const getParamLabel = (paramCode) => find(props.selectionParameters, { code: paramCode, sport: getSportLabelBySportId(props.selectedSportId) })?.name;
    const params = computed(() => map(Object.entries(data.value.parameters), (param) => ({
      param: param[0],
      type: param[1].type,
      label: `${param[0]} (${param[1].type}) - ${getParamLabel(param[0])}`,
    })));

    const toggleConfirmDelete = () => { confirmDeleteActive.value = !confirmDeleteActive.value; };
    const closeModal = () => emit('closeModal');
    const onSaveChanges = () => {
      store.dispatch('updateSelectionDisplayConfiguration', {
        key: {
          selectionCode: data.value.selectionCode,
          sport: getSportLabelBySportId(data.value.sportId),
          operatorId: data.value.operatorId,
        },
        ...data.value,
      }).then(() => {
        closeModal();
        emit('dataUpdate', data.value.sportId);
      });
    };
    const deleteSelectionConfig = () => console.log('onSelectionCofigDelete');

    return {
      data,
      isSubmitDisabled,
      confirmDeleteActive,
      onSaveChanges,
      closeModal,
      toggleConfirmDelete,
      deleteSelectionConfig,
      onDefaultNameChange,
      onLongNameChange,
      onShortNameChange,
      onPositionChange,
      params,
      toggleIsSelectionActive,
    };
  },
};
</script>

<style lang="scss">
  .edit-selection-modal-content {
    padding: 0 16px;
    .selection-info {
      position: relative;
      padding-bottom: 16px;
      .selection-info-item-label {
        font-size: 10px;
        color: $gray800;
      }
      .text-field {
        width: 100%;
        input:read-only {
          background-color: $gray400;
          color: $gray700;
        }
      }
        .selection-info-main-section,
        .selection-info-grouped-section {
          width: 100%;
          display: flex;
          gap: 12px;
          flex-wrap: wrap;
          align-items: flex-start;
          align-content: flex-start;
          .selection-info-item {
            display:  flex;
            flex-wrap: wrap;
            gap: 4px;
            width: 100%;
            span {
              display: inline-block;
              width: 100%;
              overflow: auto;
            }
            &.toggle-item {
              padding-top: 20px;
              .toggle-item-content {
                width: 100%;
                display: flex;
                flex-wrap: nowrap;
                align-items: center;
                gap: 4px;
                padding: 0 16px;
                background-color: $gray300;
                .toggle-label {
                  width: auto;
                  font-size: 14px;
                }
              }
            }
            & .param-item {
                width: 100%;
              }
            &.half-width {
              flex-wrap: nowrap;
              .half-width-item {
                width: calc(50% - 2px);
                display:  flex;
                flex-wrap: wrap;
                gap: 4px;
              }
            }
          }
          .grouped-selection-info {
            width: 100%;
            display: flex;
            gap: 16px;
          }
      }
      .selection-info-grouped-section {
        padding-top: 24px;
      }
    }
    .selection-display-config-actions,
    .confirm-delete {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 16px;
      padding: 16px 0;
      border-top: 1px solid $gray300;

      .left-side,
      .right-side {
        width: 50%;
      }
      .right-side {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 16px;
      }

      .delete-btn,
      .dismiss-btn {
        cursor: pointer;
      }
      .delete-btn {
        color: $error500;
        // Until we have a working API enpoint for this
        display: none;
      }
    }
  }
</style>
