export const selectionDisplayConfigurationsTableColumns = () => ({
  selectionCode: {
    label: 'Selection code',
    visible: true,
    order: 0,
    filterable: false,
    sortable: false,
    minWidth: 500,
  },
  position: {
    label: 'Position',
    visible: true,
    order: 1,
    filterable: false,
    sortable: false,
    minWidth: 200,
  },
  longNamePattern: {
    label: 'Long name pattern',
    visible: true,
    order: 2,
    filterable: false,
    sortable: false,
    minWidth: 400,
  },
  shortNamePattern: {
    label: 'Short name pattern',
    visible: true,
    order: 3,
    filterable: false,
    sortable: false,
    minWidth: 300,
  },
  active: {
    label: 'Active',
    visible: true,
    order: 4,
    filterable: false,
    sortable: false,
  },
  actions: {
    label: '',
    visible: true,
    order: 5,
    filterable: false,
    sortable: false,
  },
});
