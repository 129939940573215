<template>
  <component
    :is="componentName"
    :column-key="columnKey"
    :row="row"
    @updateValue="updateValue"
  />
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
import DefaultCell from './DefaultCell';
import PositionCell from './PositionCell';
import ToggleCell from './ToggleCell';
import ActionsCell from './ActionsCell';
import { getSportLabelBySportId } from '@/services/helpers/mappings-mapper';

const components = {
  default: DefaultCell,
  position: PositionCell,
  active: ToggleCell,
  actions: ActionsCell,
};

export default {
  emits: ['dataUpdate'],
  components,
  props: {
    columnKey: {
      type: String,
      required: true,
    },
    row: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const store = useStore();
    const componentName = computed(() => components[props.columnKey] || 'default');

    const updateValue = (newVal) => {
      store.dispatch('updateSelectionDisplayConfiguration', {
        key: {
          selectionCode: newVal.selectionCode,
          sport: getSportLabelBySportId(newVal.sportId),
          operatorId: newVal.operatorId,
        },
        ...newVal,
      }).then(() => {
        emit('dataUpdate', newVal.sportId);
        store.dispatch('addNotification', {
          message: 'Successfully updated selection config',
          type: 'success',
          duration: 2000,
        });
      }).catch(() => {
        store.dispatch('addNotification', {
          message: 'Unable to update selection config',
          type: 'error',
          duration: 2000,
        });
      });
    };

    return {
      componentName,
      updateValue,
    };
  },
};
</script>
