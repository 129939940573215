<template>
  <div
    @click.stop
    class="table-position-cell"
  >
    <span
      v-if="!isInputActive"
      @click.stop="setInputActive"
      class="table-label-cell"
      :class="{'has-no-value': !hasValue}"
      v-html="value"
    />
    <TextInput
      v-else
      class="position-input"
      :model-value="value"
      no-clear-button
      autofocus
      @update:modelValue="updateSelectionPosition"
      @unfocus="setInputInactive"
    />
  </div>
</template>

<script>
import { computed, ref } from 'vue';
import { isNil } from 'lodash';
import TextInput from '@/components/common/TextInput';

export default {
  emits: ['updateValue'],
  components: {
    TextInput,
  },
  props: {
    columnKey: {
      type: String,
      required: true,
    },
    row: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const hasValue = computed(() => !isNil(props.row?.[props.columnKey]));
    const value = computed(() => (hasValue.value ? props.row?.[props.columnKey] : '-'));
    const marketPositionUpdatedValue = ref(value.value);
    const isInputActive = ref(false);

    const updateSelectionPosition = (val) => {
      marketPositionUpdatedValue.value = (parseInt(val, 10) || parseInt(val, 10) === 0) ? parseInt(val, 10) : value.value;
    };
    const setInputActive = () => {
      isInputActive.value = true;
      marketPositionUpdatedValue.value = props.row?.[props.columnKey];
    };
    const setInputInactive = () => {
      isInputActive.value = false;
      if (value.value !== marketPositionUpdatedValue.value) {
        emit('updateValue', {
          ...props.row,
          position: marketPositionUpdatedValue.value,
        });
      }
    };

    return {
      value,
      hasValue,
      isInputActive,
      setInputActive,
      setInputInactive,
      updateSelectionPosition,
    };
  },
};
</script>
<style lang="scss">
  .table-position-cell {
    width: 100%;
    display: flex;
    .table-label-cell {
      width: 100%;
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: $black;
      &.has-no-value {
        color: $gray600;
      }
    }
    .position-input {
      padding: 2px 0;
    }
  }
</style>
